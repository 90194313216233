import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import rehypeReact from "rehype-react"
import {
  CourseSignupForm,
  GenericSignupForm,
  WebpackReduceBundleSignupForm,
  CodeYourOwnReduxSignupForm,
  SendMeParcelCodeSignupForm,
  TryParcelSignupForm,
  GetCodeSignupForm,
} from "../components/SignupForm"
require("prismjs/themes/prism-tomorrow.css")
var kebabCase = require("lodash.kebabcase")

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "course-signup-form": CourseSignupForm,
    "generic-signup-form": GenericSignupForm,
    "webpack-reduce-bundle-signup-form": WebpackReduceBundleSignupForm,
    "code-your-own-redux-signup-form": CodeYourOwnReduxSignupForm,
    "send-me-parcel-code-signup-form": SendMeParcelCodeSignupForm,
    "try-parcel-signup-form": TryParcelSignupForm,
    "get-code-signup-form": GetCodeSignupForm,
  },
}).Compiler

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const tags = post.frontmatter.tags || []
    return (
      <Layout
        productPage={post.frontmatter.productPage}
        location={this.props.location}
        title={siteTitle}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        {post.frontmatter.productPage ? null : (
          <h1>{post.frontmatter.title}</h1>
        )}
        {renderAst(post.htmlAst)}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        productPage
      }
    }
  }
`
